<template>
  <Layout :extranjero="true">
    <div class="row votosPorCandidaturaMovil rp" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <!-- <h4 class="mb-3">
          Consulta de votación
        </h4> -->
        <router-link
          class="btn btn-primary btn-regresar text-center d-block mt-3 mx-auto w-50 mb-4"
          to="/D/ENT/VPP"
        >
          Regresar
        </router-link>

        <select class="tipoCasilla" v-model="casilla" @change="actualizarVista">
          
          <option
            :value="item.casilla"
            v-for="(item, index) in casillas"
            :key="index"
            
          >
            
                        
            <div>
              {{ item.casilla }}
            </div>
          </option>
          <!-- <option value="301E">301 Especial </option>
          <option value="302E">302 Especial </option> -->
        </select>

        <h4>
          Diputaciones /
          <span class="primario">
            Casillas Especiales
          </span>
        </h4>

        <p>
          <b>
            Baja California Sur
          </b>
        </p>

        <p>
          El total de votos calculado y porcentaje que se muestran por Representacion Proporcional 
          de cada Casilla Especial, se refieren a los votos asentados en las Actas PREP hasta el momento.
        </p>

        <!-- <p>
          Por presentación, los decimales de los procentajes muestran sólo cuatro dígitos.
          No obstante, al considerar todos los decimales, suman 100%.
        </p> -->

        

        <div class="votosEnActasContabilizadas mt-5 mb-5">
          <p class="titulo">
            Información	 del Acta -
          </p>

          <div
            class="row align-items-end p-2 mt-5"
            v-for="(partido, index) in votos"
            :key="index"
          >
            <div class="col-4">
              <img
                :src="partido.candidatura_logo"
                :alt="partido.candidatura_nombre"
              />
            </div>
            <div class="col-4">
              <p class="mb-0">
                Votos <br />
                <b>{{ partido.candidatura_votos_cantidad }}</b>
              </p>
            </div>
            <div class="col-4">
              <p class="mb-0"><b>{{ partido.candidatura_votos_porcentaje }}%</b></p>
            </div>
          </div>
        </div>

        <div class="resumenDeLaVotacion mb-4">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion
            texto="Candidaturas no registradas"
            :numero="detalle.candidaturas_no_registradas_numero"
            :porcentaje="detalle.candidaturas_no_registradas_porcentaje"
          />
          <ResumenVotacion
            texto="Votos Nulos"
            :numero="detalle.nulos_numero"
            :porcentaje="detalle.nulos_porcentaje"
          />
        </div>

        <ObservacionesActas />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion.vue';
import {
  defineComponent, computed, ref,
} from '@vue/composition-api';

export default defineComponent({
  name: 'RepresentacionProporcionalMovil',
  components: {
    Layout,
    ObservacionesActas,
    ResumenVotacion,
  },
  setup(_, { root }) {
    const casilla = ref(root.$route.params.casilla ?? '000 Todas');
    const state = computed(() => root.$store.state);
    const cargaTerminada = computed(() => state.value.cargaTerminada);
    const detalle = computed(() => {
      if (!casilla.value) {
        const lengthCasillas = state.value.dResumenNivelRepresentacionProporcional
          .datos_casillas.length;
        return state.value.dResumenNivelRepresentacionProporcional
          .datos_casillas[lengthCasillas - 1];
      }

      return state.value.dResumenNivelRepresentacionProporcional
        .datos_casillas
        .filter((item) => item.casilla_descripcion === casilla.value)[0];
    });

    const votosXRP = computed(() => state.value.dTarjetasNivelRepresentacionProporcional);

    const casillas = computed(() => {
      const items = votosXRP.value.datos_casillas.map((item) => ({
        casilla: item.casilla_descripcion,
        seccion: item.seccion_id,
        tipo: item.casilla_tipo,
      }));

      return items.sort((a, b) => a.seccion - b.seccion);
    });

    const votos = computed(() => {
      if (!casilla.value) {
        return {};
      }
      return votosXRP.value
        .datos_casillas
        .filter((item) => item.casilla_descripcion === casilla.value)[0]
        .datos_votos.sort((a, b) => a.candidatura_id - b.candidatura_id)
        .filter((item) => item.candidatura_id < 99 && item.candidatura_id !== 0);
    });
    // eslint-disable-next-line max-len
    // const votosXRP = computed(() => state.value.dRepresentacionProporcional.datos_nombres_columnas.sort((a, b) => a.candidatura_id - b.candidatura_id));

    const actualizarVista = () => {
      if (casilla.value) {
        root.$router.push(
          `/representacion-proporcional/${casilla.value}`,
        );
        return null;
      }
      root.$router.push(
        '/representacion-proporcional',
      );
      return null;
    };

    return {
      casilla,
      cargaTerminada,
      detalle,
      actualizarVista,
      votosXRP,
      votos,
      casillas,
    };
  },
});
</script>

<style lang="scss" scoped>
.votosEnActasContabilizadas {
  .row {
    img {
      max-height: 45px;
      display: block;
      margin: 0 auto;
    }
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}

.tipoCasilla {
  width: 100%;
  display: block;
  background-color: #C791A8;
  color: #000000;
  padding: 1rem;
  margin-bottom: 30px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-regresar {
  background-color: #C791A8;
  color: #000000;
  font-weight: bold;
  border-color: transparent;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
