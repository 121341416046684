<template>
  <div class="vista-escritorio">
    <div class="votos ">
      <div class="votos-cabecera">
        <div class="titulos">
       
          <h3>Votos en Actas Contabilizadasg</h3>
        </div>
      </div>
      <div class="   contenedor-padre  mt-4 " >
        <button
          class="btn-fijo btn-izquierda"
          @click="scrollIzquierda"
          :disabled="horizontalScrollValue === 0"
        >
          <font-awesome-icon icon="chevron-left" class="fa-3x" />
        </button>    

         <div class="scroll">
          <div  class="" 
          :style="{ transform: `translateX(-${horizontalScrollValue}px)`}"
          style="transition: transform 0.5s ease;" 
           >
          <div class=" rowFlex " id="row-flex">
                      <div v-for="(partido, index) in votos.partidos" 
                      :key="index"
                      >
                          <div class="resumen-voto-candidatura">
                              <div class="grilla-datos  ">
                                  <div class="grilla-datos-candidatura">                                      
                                        <div class="foto" style="text-align: center">
                                              <img 
                                              :src="partido.partido_logo" 
                                              :alt="partido.partido_nombre"
                                              style="max-height: 40px">
                                        </div>
                                        <div class="nombre text-center mb-4">

                                        </div>
                                  </div>                                  
                              </div>

                              <div class="grilla-resumen">
                                  <div class="total text-left">
                                    <p class="mb-0 font-weight-bold">
                                          Votos <br />
                                          <span >
                                            {{ partido.partido_total_votos_general_numero }}
                                          </span>
                                                                               
                                        </p>                                      
                                  </div>
                                  <div class="porcentaje text-righ">
                                      <p class="mb-0 font-weight-bold">
                                        Porcentaje <br>
                                        <span >
                                            {{partido.partido_total_votos_general_porcentaje}}%
                                        </span> 
                                        
                                      </p>
                                    </div>                                  
                                </div>  
                            </div>
                        </div>
          </div>
         </div>
         </div>

        <button
          class="btn-fijo btn-derecha"
           @click="scrollDerecha"
          :disabled="horizontalScrollValue > maximoLogitudScroll"
          >
           <font-awesome-icon icon="chevron-right" class="fa-3x" />
        </button>
                              
      </div>
                
                <div class="text-left mb-3" v-if="eleccion === 'Diputaciones'">
             
                  <p class="pt-4">
                    <b>
                      En esta tabla se considera la cantidad de votos asentada
                      en las actas PREP de Casilla Especial de Representación
                      Proporcional.
                    </b>
                  </p>
                </div>
                <!-- <div
        class="graficas-votos-por-partido"
        :class="eleccion"
        v-if="mostrarGrafica"
      >
        <div class="tabla">
          <ul>
            <li class="titulos">
              <div class="numeros-eje-y">
                <ul>
                  <li ref="panelVotos">
                    <span>{{ obtenerEscala * 4 }}</span>
                  </li>
                  <li>
                    <span>{{ obtenerEscala * 3 }}</span>
                  </li>
                  <li>
                    <span>{{ obtenerEscala * 2 }}</span>
                  </li>
                  <li>
                    <span>{{ obtenerEscala }}</span>
                  </li>
                  <li>
                    <span>0</span>
                  </li>
                </ul>
              </div>
              <div class="texto">
                <ul>
                  <li>
                    <h2>Votos</h2>
                    <h3>Total</h3>
                  </li>
                  <li>
                    <h4>Porcentaje</h4>
                  </li>
                  <li>
                    <h4>En Casillas</h4>
                  </li>
                  <li>
                    <h4>Anticipados</h4>
                  </li>

                  <li class="entidad">
                    <h4>En la entidad</h4>
                  </li>
                  <li class="extranjero">
                    <h4>En el Extranjero</h4>
                  </li>
                </ul>
              </div>
            </li>
            <li
              v-for="(partido, index) in votosXPartidoGraficas.partidos"
              :key="index"
            >
              <div>
                <div
                  class="barra"
                  :style="calcularEstiloElemento(partido, index)"
                ></div>
              </div>

              <div>
                <div class="avatar-nombre">
                  <div>
                    <img
                      v-if="partido.partido_logo != 'archivo_logo_partido.png'"
                      :src="partido.partido_logo"
                      alt=""
                      style="width:50px; height:50px"
                    />
                    <div
                      v-else
                      :class="
                        calcularEspacios(partido.partido_nombre)
                          ? 'candidatura-especial'
                          : ''
                      "
                      style="height:50px; text-align:center"
                    ></div>
                  </div>
                  <div class="nombres">
                    <span
                      :class="
                        partido.partido_logo == 'archivo_logo_partido.png'
                          ? 'salto-linea'
                          : ''
                      "
                    ></span>
                  </div>
                </div>
                <div class="total-votos">
                  <div class="votos-tabla">
                    <ul>
                      <li>
                        <h3>
                          {{ partido.partido_total_votos_general_numero }}
                        </h3>
                      </li>
                      <li>
                        <h4>
                          {{ partido.partido_total_votos_general_porcentaje }}%
                        </h4>
                      </li>
                      <li>
                        <h4>
                          {{ partido.partido_total_votos_casilla_numero || 0 }}
                        </h4>
                        <h4>{{partido.partido_total_votos_general_numero}}</h4>
                      </li>
                      <li>
                        <h4>
                          {{
                            partido.partido_total_votos_anticipados_numero || 0
                          }}
                        </h4>
                        <h4>{{partido.partido_total_votos_general_numero }}</h4>
                      </li>

                      <li class="entidad">
                        <h4>
                          {{ partido.partido_total_votos_entidad_numero }}
                        </h4>
                      </li>
                      <li class="extranjero">
                        <h4>
                          {{ partido.partido_total_votos_extranjero_numero }}
                        </h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div> -->

     
      <div class="resumen">
        <!-- Elecciones de gubernatura -->
        <div class="resumen-votacion " v-if="eleccion == 'Gubernatura'">
          <h3>Resumen de la votación</h3>
          <div>
            <div class="cuadro-principal  ">
              <div>
                <h4>En la Entidad</h4>
                <h5>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero
                  }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje
                  }}%
                </h6>
              </div>
              <div class="icono-mas">
                +
              </div>
              <div>
                <h4>En el extranjero</h4>
                <h5>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_extranjero_numero
                  }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_extranjero_porcentaje
                  }}%
                </h6>
              </div>
            </div>
            <div class="icono-mas">
              +
            </div>
            <div>
              <h4>Candidaturas no registradas</h4>
              <h5>
                {{
                  gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero
                }}
              </h5>
              <h6>
                {{
                  gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje
                }}%
              </h6>
            </div>
            <div class="icono-mas">
              +
            </div>
            <div>
              <h4>Votos Nulos</h4>
              <h5>
                {{ gubernaturaResumenNivelEntidad.total_votos_nulos_numero }}
              </h5>
              <h6>
                {{
                  gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje
                }}%
              </h6>
            </div>
            <div class="icono-igual">
              =
            </div>
            <div class="total-de-votos">
              <h4>Total de votos</h4>
              <h5>
                {{ gubernaturaResumenNivelEntidad.total_votos_general_numero }}
              </h5>
              <h6>
                {{
                  gubernaturaResumenNivelEntidad.total_votos_general_porcentaje
                }}%
              </h6>
            </div>
          </div>
        </div>

        <div class="resumen-votacion " v-else>
          <h4 class="mb-2">Resumen de la votación</h4>
          <div class="grilla-suma mr-5">
            <div class="acumulados text-center">
              <p class="font-weight-bold">
                Votos acumulados
                <span id="tooltip">
                  <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                  <span class="tooltiptext">
                    Es la sumatoria de los votos obtenidos <br />
                    por Partido Político.
                  </span>
                </span>
                <br />
                {{
                  gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero
                }}
                <br />
                <span class="porcentaje">
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje
                  }}%
                </span>
              </p>
            </div>
            <div class="icono-suma">
              <font-awesome-icon icon="plus" class="fa-2x" />
            </div>
            <div class="no-registradas text-center">
              <p class="font-weight-bold">
                Candidaturas no registradas <br />
                {{
                  gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero
                }}
                <br />
                <span class="porcentaje">
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje
                  }}%
                </span>
              </p>
            </div>
            <div class="icono-suma">
              <font-awesome-icon icon="plus" class="fa-2x" />
            </div>
            <div class="nulos text-center">
              <p class="font-weight-bold">
                Votos Nulos                                 
                <br />
                {{ gubernaturaResumenNivelEntidad.total_votos_nulos_numero }}
                <br />
                <span class="porcentaje">
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje
                  }}%
                </span>
              </p>
            </div>
            <div class="icono-suma">
              <font-awesome-icon icon="equals" class="fa-2x" />
            </div>
            <div class="total-de-votos">
              <div class="d-flex ml-3">
                <h4 class="mr-2">Total de votos</h4>
                <span id="tooltip">
                    <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                    <span class="tooltiptext">
                      Se refiere a la suma total de votos que
                      realiza el sistema informático con base en los datos
                      que muestran en cada Acta; podrás consultar la cifra
                      del total de votos mostrado en cada Acta en la base
                      de datos del PREP (numeral 26, fr. XIV del Anexo 13 del
                      Reglamento de Elecciones).  
                    </span>
                </span>
              </div>

              <h5>
                {{ gubernaturaResumenNivelEntidad.total_votos_general_numero }}
              </h5>
              <h6>
                {{
                  gubernaturaResumenNivelEntidad.total_votos_general_porcentaje
                }}%
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="votos-extranjero" v-if="eleccion === 'Gubernatura'">
      <div>
        <font-awesome-icon icon="globe-americas" />
        <h2>Voto en el extranjero</h2>
        <router-link to="/G/VE/" class="boton-azul">Ver Actas</router-link>
      </div>
    </div>

   

    <detalle-eleccion-partidos-politicos
      v-if="anchoPantalla >= 992"
      :resumen="gubernaturaResumenNivelEntidad"
      :votos="votos"
    />
  </div>
</template>
<script>
import Cabecera from "@/components/Escritorio/componentes/Cabecera";
import Cuerpo from "@/components/Escritorio/componentes/Cuerpo";
import Compartir from "@/components/Compartir.vue";
import EstadisticaEntidad from "@/components/EstadisticaEntidad.vue";
import ListaNominal from "@/components/ListaNominal.vue";
import PorcentajeParticipacion from "@/components/PorcentajeParticipacion.vue";

import PieDePagina from "@/components/Escritorio/componentes/PieDePagina.vue";
import AccesoRepresentacionProporcional from "@/components/Movil/componentes/AccesoRepresentacionProporcional.vue";
import DetalleEleccionPartidosPoliticos from "@/components/DetalleEleccionPartidosPoliticos.vue";
import numeral from "numeral";

export default  {
  name: "VotosPorPartidoPoliticoEscritorio",
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    EstadisticaEntidad,
    ListaNominal,
    PorcentajeParticipacion,
    PieDePagina,
    AccesoRepresentacionProporcional,
    DetalleEleccionPartidosPoliticos
  },
  computed: {
    eleccion() {
      // if (this.$route.params.eleccion === "G") {
      //   return "Gubernatura";
      // }
      // if (this.$route.params.eleccion === "D") {
      // }
      return "Diputaciones";
      // if (this.$route.params.eleccion === "A") {
      //   return "Ayuntamientos";
      // }

      // return "";
    },
    detalle() {
      return this.$store.state.entidad.votosPartidosPoliticos;
    },
    gubernaturaResumenNivelEntidad() {
      // const categorias = {
      //   G: this.$store.state.gResumenNivelEntidad,
      //   A: this.$store.state.aResumenNivelEntidad,
      //   D: this.$store.state.dResumenNivelEntidadConRP
      // };
      // const { eleccion } = this.$route.params;

      return this.$store.state.dResumenNivelEntidadConRP;
    },
    votosXPartidoTarjetaEscritorio() {
      // if (this.$route.params.eleccion === "G") {
      //   return this.$store.state.gVotosXPartidoTarjeta;
      // }
      // if (this.$route.params.eleccion === "D") {
      // }
      return this.$store.state.dVotosXPartidoTarjeta;
      // if (this.$route.params.eleccion === "A") {
      //   return this.$store.state.aVotosXPartidoTarjeta;
      // }

      // return {};
    },
    votosXPartidoGraficas() {
      // if (this.$route.params.eleccion === "G") {
      //   return this.$store.state.gVotosXPartidoGraficas;
      // }
      // if (this.$route.params.eleccion === "D") {
      // }
      return this.$store.state.dVotosXPartidoGraficas;
      // if (this.$route.params.eleccion === "A") {
      //   return this.$store.state.aVotosXPartidoGraficas;
      // }

      // return {};
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
    tresSeleccionados() {
      let seleccionados = 0;

      this.votos.partidos.map(partido => {
        if (partido.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
    obtenerMaximo() {
      const max = Math.max.apply(
        Math,
        this.votosXPartidoGraficas.partidos.map(p =>
          numeral(p.partido_total_votos_general_numero).value()
        )
      );

      return max;
    },
    obtenerEscala() {
      return Math.trunc((this.obtenerMaximo * 1.2) / 4);
    }
  },
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ["", "Votos", { role: "style" }],
        ["", 21638, "#2659A5"],
        ["", 55059, "#4F944B"],
        ["", 4525, "#E3C650"],
        ["", 178107, "#D63F33"],
        ["", 123572, "#E98932"],
        ["", 6579, "#00A6B4"],
        ["", 2262, "#A83A28"],
        ["", 54817, "#5D2C7F"]
      ],
      chartOptions: {
        height: 200,
        width: 1550,
        bar: { groupWidth: "54" },
        legend: { position: "none" },
        colors: ["#DD3B5D", "#00B6FF", "#9E9E9E"],
        vAxis: { gridlines: { count: 4 }, textStyle: { color: "#BABABA" } }
        // colors: ['#2659A5', '#4F944B', '#E3C650', '#D63F33', '#E98932','#00A6B4','#A83A28','#5D2C7F']
      },
      mostrarGrafica: true,
      altoVotos: 10,
      votos: {},
      random: 0,
      horizontalScrollValue: 0,
      maximoLogitudScroll: 1000,
    };
  },
  methods: {
    scrollIzquierda() {
      this.horizontalScrollValue -= 100;
      if (this.horizontalScrollValue < 0) {
        this.horizontalScrollValue = 0;
      }
    },
    scrollDerecha() {
      this.horizontalScrollValue += 100;
      if (this.horizontalScrollValue > this.maximoLogitudScroll) {
        this.horizontalScrollValue = this.maximoLogitudScroll;
      }
    },
    manejarMostrarGrafica(value) {
      //   console.log(value)
      this.mostrarGrafica = value;
    },
    calcularAltoSobrante(vPartido) {
      const factor = this.altoVotos * 5 + 15;
      const valH =
        (factor * vPartido.partido_total_votos_general_porcentaje) / 100;
      return `padding-top:${String(factor - valH)}px;`;
    },
    calcularEstiloElemento(vPartido) {
      const votos = numeral(
        vPartido.partido_total_votos_general_numero
      ).value();
      const valH = (votos * 140) / (this.obtenerMaximo * 1.2);
      return (
        `${"width: 56px;" + "height:"}${String(valH)}px;` +
        `background-color:${vPartido.partido_color};`
      );
    },
    onCheckboxChange(e) {
      this.$forceUpdate();
      this.random = Math.random();
    },
    calcularEspacios(partido) {
      return partido.indexOf(" ") >= 0;
    },
    calcularAltoSobrante(vPartido) {
      const factor = this.altoVotos * 5 + 15;
      const valH =
        (factor * vPartido.partido_total_votos_general_porcentaje) / 100;
      return `padding-top:${String(factor - valH)}px;`;
    },
    onCheckboxChange(e) {
      this.$forceUpdate();
      this.random = Math.random();
    },
    calcularEspacios(partido) {
      return partido.indexOf(" ") >= 0;
    }
  },
  mounted() {
    this.altoVotos = this.$refs.panelVotos.clientHeight;
  },
  created() {
    // if (this.$route.params.eleccion === "G") {
    //   this.votos = this.$store.state.gVotosXPartidoTarjeta;
    // } else if (this.$route.params.eleccion === "D") {
    // } else if (this.$route.params.eleccion === "A") {
    //   this.votos = this.$store.state.aVotosXPartidoTarjeta;
    // }
    this.votos = this.$store.state.dVotosXPartidoTarjeta;
  }
};
</script>

<style lang="scss" scoped>
.contenedor-scroll-externo {
  overflow-x: auto;
  width: 100%;
}
.rowFlex {
  display: flex;
  flex-flow: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  scroll-behavior: smooth;

  .resumen-voto-candidatura {
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    border: 1px solid #582D73;
    max-width: 150px;
    margin-right: 10px;
    .grilla-datos-candidatura {
      margin: 10px;
      .foto > svg {
        max-width: 50px;
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
      }
    }
    .grilla-resumen {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-end;
      gap: 15px;
      margin-top: 10px;
      background-color: #f4f4f4;
      padding: 5px;
    }
  }
}

.rowFlex::-webkit-scrollbar
{
  width: 11px;
  &:hover {
    cursor: pointer;
  }
}

.rowFlex::-webkit-scrollbar-thumb
{
  background-color: #90A4AE;
  border-radius: 6px;
  border: 3px solid #CFD8DC;

  &:hover {
    cursor: pointer;
  }
}

.contenedor-padre {
  display: grid;
  width: 100%;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  gap: 15px;
  .scroll{
    display: flex;
    transition: transform 0.3s ease; /* Agrega una transición suave al desplazamiento */
    width: 100%;
  }
  .btn-fijo {
    background: transparent;
    border: none;
    color: #707070;
    z-index: 1;
    transition: all ease .2s;

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }
}


// .total-votos{
//   background-color: #F4F4F4;
// }
// .tabla-responsiva {
//           overflow-x: auto;
//         }
// .votos-extranjero {
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-image: url("../../../assets/Fondo_Mapa.png");
// }

// .votos-representacion {
// text-align: center;
// border-radius: 20px;
// background-color: white;
// padding: 20px;
// margin-bottom: 30px;
// background-size: cover;
// background-repeat: no-repeat;
// }
</style>
