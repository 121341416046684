<template>
  <div class="vista-escritorio" v-if="cargaTerminada">

      <div class="votos simulacro ">
        <div class="votos-cabecera ">
          <div class="titulos  ">
            <h3 class="mt-4"> Detalle de votos por Casilla </h3>
            

            <div class="grilla-estado-acta mb-4 ml-4">
              <procedencia-acta />
            </div>

            <div class="table-responsive  ">
              <table class="table table-striped ">
                <thead>
                  <tr class="text-center">
                    <th v-for="(columna, index) in votosXRP.datos_nombres_columnas" :key="index" style="min-width: 100px;">
                      <span v-if="columna.candidatura_logo !== 'sin logo'">
                        <img
                          :src="columna.candidatura_logo"
                          :alt="columna.candidatura_nombre"
                          />
                        {{ agregarOrden(columna.candidatura_logo) }}
                      </span>
                      <div v-else >
                        {{columna.candidatura_nombre}}
                      </div>
                    </th>
                  </tr>
                </thead>
                
                <tbody>
                  <tr
                    v-for="(casilla, index) in votos"
                    :key="index"
                    class="text-center"
                  >
                    <td class="font-weight-bold">
                      <!-- DEBERÁ IR EL URL DEL ACTA-->
                      <!-- <router-link
                        :to="casilla.datos_votos[2].candidatura_votos_cantidad"
                        class="font-weight-bold"
                      > -->
                        <div>
                          {{ casilla.casilla_descripcion }}
                        </div>
                      <!-- </router-link> -->
                    </td>
                    <td class="procedencias">
                      <a
                        :href="calcularUrlActaDigitalizada(casilla.datos_votos[1])"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <nivel-procedencia
                          :procedencia="parseInt(casilla.datos_votos[1].candidatura_logo)"
                        />
                      </a>
                    </td>
                    <td v-for="(logo, key1) in partidosOrden" :key="key1">
                      <span v-for="(item, index) in casilla.datos_votos" :key="index">
                        <span v-if="item.candidatura_logo !== 'sin dato' && item.candidatura_logo !== 0 && item.candidatura_logo !== 1 && item.candidatura_logo !== 'sin logo'">
                          <span v-if="getLogo(item.candidatura_logo) === logo">
                            <p class="mb-0">
                              {{ item.candidatura_votos_cantidad }}
                            </p>
                          </span>
                        </span>
                      </span>
                    </td>
                    <td>
                      {{ casilla.datos_votos[casilla.datos_votos.length-4].candidatura_votos_cantidad }}
                    </td>
                    <td>
                      {{ casilla.datos_votos[casilla.datos_votos.length-3].candidatura_votos_cantidad }}
                    </td>
                    <td>
                      {{ casilla.datos_votos[casilla.datos_votos.length-2].candidatura_votos_cantidad }}
                    </td>
                    <td>
                      {{ casilla.datos_votos[casilla.datos_votos.length-1].candidatura_votos_cantidad }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
     

    
    
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Compartir from '@/components/Compartir.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';

export default defineComponent({
  components: {
     Compartir, ProcedenciaActa, NivelProcedencia,
  },
  data() {
    return {
      partidosOrden: [],
    };
  },
  methods: {
    agregarOrden(dato) {
      //agregar al array solo si no existe
      //obtener ultimo texto de un split
      dato = dato.split('/').pop().split('.').shift();
      
      if (!this.partidosOrden.includes(dato)){
        this.partidosOrden.push(dato);
      }
    },
    getLogo(dato) {
      return dato.split('/').pop().split('.').shift();
    },
  },
  name: 'RepresentacionProporcionalEscritorio',
  setup(_, { root }) {
    const state = computed(() => root.$store.state);
    const cargaTerminada = computed(() => state.value.cargaTerminada);
    const votosXRP = computed(() => state.value.dRepresentacionProporcional);
    const resumen = computed(() => state.value.dResumenNivelRepresentacionProporcional);
    const longitudResumen = resumen.value.datos_casillas.length;
    const resumenTotales = computed(() => resumen.value.datos_casillas[longitudResumen - 1]);

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const votos = computed(() => votosXRP.value.datos_casillas
      .sort((a, b) => a.seccion_id - b.seccion_id));

    return {
      ...useCalcularUrlActaEscaneada(),
      cargaTerminada,
      votosXRP,
      resumen,
      votos,
      resumenTotales,
    };
  },
});
</script>

<style lang="scss" scoped>

.grilla-estado-acta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }
}
// .scroll{
//   overflow-x: auto;
// }
.table-responsive {
  overflow-x: auto; /* Agrega scroll horizontal */
  white-space: nowrap; /* Evita que el contenido se divida en varias líneas */
  max-width: 1400px; 
  thead {
    tr {
      background-color:#582D73;
      color: #FFF;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
          /* max-width: 35px; */
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-height: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}
</style>
