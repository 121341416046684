<template>
    <div class="porcentaje-participacion simulacro">
     <div class="votosEnActasContabilizadas">
        <h3>
          Fórmula | Porcentaje de Participación Ciudadana        
        </h3>
        <p>
          Se calcula considerando: Total de votos de las Actas contabilizadas, multiplicado por 100, dividido entre la Lista Nominal de las
          casillas con Actas contabilizadas.
        </p>
     </div>
      
      <div class="row d-flex justify-content-center">
      
      <div class="col-12 col-sm-8 col-lg-6 col-xl-6">
        <div class="listaNominal mb-2">

          <div class="row listaNominal-actasContabilizadas ">
            <div class="col-7 col-sm-6 col-lg-5 ml-auto contorno ">
             <div class="d-flex">
               <h6 class="mb-0 pt-3">Total de votos </h6>
              <img class="mt-3 ml-2" src="../assets/circle-a.png" alt="">
             </div>
                <p class="texto  text-left mb-0">
                  (Sin considerar la votación en Casillas  Especiales MR ) 
                  
                </p>
              

              <h2 class="text-center pt-2 mb-0 font-weight-bold primario">
                {{ estadisticas.votos_casilla_basica_contigua_extraordinaria_numero}}
               
              </h2>
              
            </div>            
            <div class="texto pt-5 mt-5">
                  <h2 class="text-center  contraste">
                    X 100
                  </h2>
            </div>
          </div>
        </div>

        <div class="actas-contabilizadas d-block justify-content-center col-12 col-lg-9 
         ml-auto row pt-0">
            <div class=" " :class="{'primario' : this.$store.state.esOscuro}">              
              <h4 class="text-center">
                <strong>                 
                  {{ estadisticas.valor_lista_nominal_actas_contabilizadas_numero }}
                </strong>
              </h4>
              
            </div>
            <p class="text-center">
                (Lista Nominal de las casillas  con Actas Contabilizadas)
            </p>
          </div>
          <!-- <div class="justify-content-center col-12 ml-auto row pt-0">
            <p class="text-center">
                (Lista Nominal de las casillas  con Actas Contabilizadas)
            </p>
          </div> -->




      </div>    
      <div class="icono-suma d-flex align-items-center pt-3  me-1">
        <font-awesome-icon icon="equals" class="fa-2x " />
      </div>

      <div class="col-12 col-lg-6 col-xl-5 contenedor">
        <div class="porcentaje-participaciones  m-3">
          <div class="votosTotalesAvance  mb-4">
            
            <div class="grilla mt-5 pt-2 d-block">
              
                <div class="row">
                  <div class="col-12"  >
                  <h3 class="mb-0">
                    Participación ciudadana
                  </h3>
                </div>

                <div class="total col-12 ">
                  <h1 class="mb-0 text-left mt-4 pt-2">
                    {{
                      ((Number(estadisticas.votos_casilla_basica_contigua_extraordinaria_numero.replace(',','')) * 100) /
                        (Number(estadisticas.valor_lista_nominal_actas_contabilizadas_numero.replace(',',''))) || 0
                      ).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + '%'
                    }}
                  </h1>
                </div>
                </div>
             

             

              

          </div>
        </div>
          
        </div>  
      </div>
      
    </div>


     <div class="texto1 mt-4 ">
        <p class="">
        Por su naturaleza, las <b>casillas especiales no cuentan con lista nominal</b> , por lo tanto, para el cálculo
        de la participación ciudadana, la cantidad de votos asentadas en las Actas PREP de casilla especial,
        <b>se deberá considerar hasta el último corte de información que se publique, al cierre del PREP </b> (numeral 29, fr IX del Anexo 13 del Reglamento de Elecciones).
      </p>
     </div>

    </div>
  </template>
  <style lang="scss" scoped>
  h3{
        color: #582D73;
        font-weight: bolder;
        
  }
  .contorno {
    border: 3px dashed #707070;
    margin-bottom: 5px;
    // border-spacing: 150px;
    img{
      // padding-top: -5px;
      max-width: 15px;
      max-height: 15px;
    }
    h2 {
        font-size: 48px;
        
      }
    .texto{
      
      font-size: 12px;
    }
   
  }
  .texto{
      h2 {
        font-size: 48px;
        // font-weight: bold;
      }
      
      
    }
    @media (max-width: 768px) {
    .porcentaje-participacion{
      background-color: rgba(117, 116, 116, 0.057);
      margin-bottom: 15px;
      border-radius: 10px;
      .texto1{
        margin: 10px;
        
      }
      .votosEnActasContabilizadas{
        h3{
          font-size: 17px;
          color: #000000;
          padding-top: 15px;
          margin-left: 15px;
          margin-right: 20px;
        }  
        p{
          font-size: 13px;
          text-align: justify;
          margin-left: 15px;
          margin-right: 20px;
        }  
      }
      div{
        p{
          font-size: 14px;
          text-align: justify;
        }
      }
      .icono-suma{
        display: flex;
      }
      .contenedor{
        text-align: center;
       div{
        margin-top: 5px!important;
        // padding-top: 0;
       }
       h3{
        color: #000000!important;
        text-decoration: underline;
        font-size: 27px;
        }

        .total{
            h1{
            text-align: center!important;
            margin-top: 0!important;
            padding-top: 0;

            }
            
        }
      }
    

    }
 }
 
    @media (max-width: 576px) {       
    //  .contenedor{
    //   display: flex;

    //     h3{
    //       order: 2;
    //     }
    //     h1{
    //       order: 1;
    //     }
    //  }
    .texto{
      h2 {
        font-size: 42px;
        padding-right: 15px;
        margin-left: 8px;
        // padding-top: 15px;
         font-weight: bold;
        }  
      }
      .listaNominal{
        border-bottom: 4px solid #707070;
        margin-bottom: 20px; 
        margin-top: 20px; 

    }
    h4{
      // margin-top: 17px;
      strong{
        font-size: 40px;
        font-weight: bold;
      }
    }
    
    
}
  </style>
  <script>
  import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
  import Urbano from '@/components/Movil/componentes/Iconos/Urbano';
  import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
  import NoUrbano from '@/components/Movil/componentes/Iconos/NoUrbano';
  
  export default {
    name: 'EstadisticaEntidad',
    components: {
      ProgresoVertical,
      GraficaListaNominal,
      Urbano,
      NoUrbano,
      isMobile: true,
    },
    data() {
      return {
        randomKey: Math.random(),
      };
    },
    mounted() {
    // Agrega un listener para detectar cambios en la media query
    const mediaQuery = window.matchMedia("( max-width: 768px)");
    this.handleMediaQuery(mediaQuery);
    mediaQuery.addListener(this.handleMediaQuery);
    },
    computed: {
      estadisticas() {
        if (this.$route.params.eleccion === 'D') {
          return this.$store.state.dEstadisticasNivelEntidad;
        } if (this.$route.params.eleccion === 'A') {
          return this.$store.state.aEstadisticasNivelEntidad;
        }
        return this.$store.state.gEstadisticasNivelEntidad;
      },
      colorEsperadas() {
        if (this.$store.state.esOscuro) {
          return 'fd2a71';
        }
        return 'C791A8';
      },
      colorCapturadas() {
        if (this.$store.state.esOscuro) {
          return 'fe9db0';
        }
        return 'B283B9';
      },
      colorContabilizadas() {
        if (this.$store.state.esOscuro) {
          return 'fec5cd';
        }
        return '582D73';
      },
      colorUrbano() {
        if (this.$store.state.esOscuro) {
          return 'F1B1D7';
        }
        return 'd2127e';
      },
      colorNoUrbano() {
        if (this.$store.state.esOscuro) {
          return 'E97FBD';
        }
        return '79144c';
      },
      styleLetter() {
        if (this.$store.state.esOscuro) {
          return 'color:black';
        }
        return 'color:white';
      },
      styleUrbano() {
        if (this.$store.state.esOscuro) {
          return 'background-color: #F1B1D7 !important; color: black !important;';
        }
        return 'background-color: #C791A8 !important;';
      },
      styleNoUrbano() {
        if (this.$store.state.esOscuro) {
          return 'background-color: #E97FBD !important; color: black !important;';
        }
        return 'background-color: #582D73 !important;'; 
      },
      eleccion() {
        return this.$route.params.eleccion;
      },
    },
    methods: {
      handleMediaQuery(mediaQuery) {
      this.isMobile = mediaQuery.matches;
    },
      obtenerAvance(avance) {
        // console.log(avance);
        return Math.ceil(parseFloat(avance));
      },
    },
    watch: {
      eleccion: {
        handler(val, oldVal) {
          if (val !== oldVal) {
            this.randomKey = Math.random();
          }
        },
      },
    },
  };
  </script>
  