<template>
  <div class="vista-escritorio" v-if="cargaTerminada">
    <cabecera />
    <cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos ">

            <!-- <div class="titulos scroll"> -->

            <h2>
              Diputaciones - <span><strong>Casillas Especiales</strong></span>
            </h2>
            <div>
              <h4>
                Baja California Sur
              </h4>
            </div>

            <div class="">
                <h6 style="font-size: 15px; font-weight: normal;">
                  El total de votos calculado y porcentaje que se muestran por Representación Proporcional
                  de cada Casilla Especial, se refiere a los votos asentados en las Actas PREP hasta el momento. <br />
                  Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
                  No obstante, al considerar todos los decimales, suman 100%.
                </h6>
            </div>

            <!-- <h3>Votos en Actas Contabilizadas</h3>   -->

            <!-- <votos-por-partido-politico-tarjetas/> -->
            <!-- <votos-por-partido-politico-tarjetas/> -->

            <votos-casilla-especial/>
            
            

            <!-- RESUMEN DE VOTACIONES -->
            <!-- <div class="row px-3"> 
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div class="grilla-suma">

                  <div class="acumulados text-center">
                  <p class="font-weight-bold">
                    Votos acumulados
                    <span id="tooltip">
                      <font-awesome-icon
                        icon="info-circle"
                        class="fa-sm fa-info"
                      />
                      <span class="tooltiptext">
                        Es la sumatoria de los votos obtenidos <br />
                        por Partido Político, Coalición <br />
                        o Candidatura Independiente.
                      </span>
                    </span>
                    <br />
                    {{ resumen.total_votos_acumulados_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.total_votos_acumulados_porcentaje }}%
                    </span>
                  </p>
                </div>

                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>

                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumenTotales.candidaturas_no_registradas_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenTotales.candidaturas_no_registradas_porcentaje }}%
                      </span>
                    </p>
                  </div>

                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>

                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos Nulos <br />
                      {{ resumenTotales.nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenTotales.nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumenTotales.total_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumenTotales.total_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>

                
              </div>
            </div>  -->

          </div>
          
        </div>
      </div>
      <detalle-casilla />
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <observaciones-actas />
          </div>
        </div>
      </div>

      <!-- <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos,
          por tanto, no tienen efectos jurídicos.
        </p>
      </div> -->
    </cuerpo>
    <pie-de-pagina />
    <compartir />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import VotosCasillaEspecial from '@/components/Escritorio/vistas/VotosCasillaEspecial.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';
import Cabecera from '../componentes/Cabecera.vue';
import Cuerpo from '../componentes/Cuerpo.vue';
import DetalleCasilla from '@/components/DetalleCasilla.vue';
import PieDePagina from '../componentes/PieDePagina.vue';
import VotosPorPartidoPoliticoTarjetas from '@/components/Escritorio/vistas/VotosPorPartidoPoliticoTarjetas.vue';


export default defineComponent({
  components: {
    Cabecera, 
    Compartir, 
    Cuerpo, 
    VotosCasillaEspecial,
    DetalleCasilla ,
    VotosPorPartidoPoliticoTarjetas,
    ObservacionesActas, 
    PieDePagina, 
    ProcedenciaActa, 
    NivelProcedencia,
  },
  name: 'RepresentacionProporcionalEscritorio',
  setup(_, { root }) {
    const state = computed(() => root.$store.state);
    const cargaTerminada = computed(() => state.value.cargaTerminada);
    const votosXRP = computed(() => state.value.dRepresentacionProporcional);
    const resumen = computed(() => state.value.dResumenNivelRepresentacionProporcional);
    const longitudResumen = resumen.value.datos_casillas.length;
    const resumenTotales = computed(() => resumen.value.datos_casillas[longitudResumen - 1]);

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const votos = computed(() => votosXRP.value.datos_casillas
      .sort((a, b) => a.seccion_id - b.seccion_id));

    return {
      ...useCalcularUrlActaEscaneada(),
      cargaTerminada,
      votosXRP,
      resumen,
      votos,
      resumenTotales,
    };
  },
});
</script>

<style lang="scss" scoped>

.grilla-estado-acta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color:#582D73;
      color: #FFF;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
          /* max-width: 35px; */
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-height: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          // font-weight: bold !important;
        }
      }
    }
  }
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}
</style>
