<template>
  <div class="lista-nominal"> 

    <div class="row simulacro">
      
      <div class="col-12 col-lg-6 col-xl-6">
        <div class="listaNominal mb-5">
          <h5 class="font-weight-bold mb-3">
            Lista Nominal
          </h5>

         

          <div class="row listaNominal-actasContabilizadas">
            <div class="col-10 col-lg-8 ml-auto">
              <p class="col-12 text-right mb-0">
                Lista Nominal de las casillas<br />
                con Actas Contabilizadas
              </p>

              <h4 class="text-right mr-3 pt-2 font-weight-bold primario">
                {{ estadisticas.valor_lista_nominal_actas_contabilizadas_numero }}
              </h4>
            </div>
          </div>
         
          <GraficaListaNominal class="mb-4"
            v-bind:value="this.$store.state.esOscuro"
            :color="colorUrbano"
            :numero="estadisticas.valor_lista_nominal_oficial_numero"
            :porcentaje="estadisticas.valor_lista_nominal_actas_contabilizadas_porcentaje"
            :key="randomKey"
          />

        </div>
      </div>
      
      <div class="col-12 col-lg-6 col-xl-5">
        <div class="participacion-ciudadana m-3">
          <p class="font-weight-bold">
            Votos
          </p>        
        <div >
          <div class="votosTotalesAvance  contorno mb-1">
            <div class="grilla mb-2 d-flex align-items-center">
              <div class="leyenda d-flex ">

                <div class="iconos mt-2 ml-1">
                  <img src="@/assets/circle-a.png" alt="">
                </div>
                <!-- <p class="font-weight-bold mb-0" v-if="eleccion === 'G'"> -->
                <p class="font-weight-bold mb-0" v-if="!isMobile">
                  Votos en Casillas Básicas, Contiguas,
                  Extraordinarias y Mesas de Escrutinio                  
                </p>                
                <p class="font-weight-bold mb-0 d-flex" v-else>                  
                  Votos en Casillas Básicas, 
                  Contiguas, Extraordinarias. 
                </p>
              </div>
              <div class="total mr-3">
                <h1 class="mb-0">
                  <strong>
                    {{ estadisticas.votos_casilla_basica_contigua_extraordinaria_numero}}
                  </strong>                
                </h1>
              </div>
            </div>           
          </div>

          <div class="votosTotalesAvance" >
            <div class="grilla leyenda1 opacidad mb-2 d-flex align-items-center ">
              <div class="d-flex ml-2 ">
                <div class="iconos mt-2">
                  <img src="@/assets/circle-b.png" alt="">
                </div>
                <p class="font-weight-bold mb-0">
                  Votos en Casillas <br> Especiales
                  <template>MR</template>
                  <!-- <template v-if="eleccion === 'D'">MR</template> -->

                </p>
                
              </div>
              <div class="icono-suma col-2">
                    <font-awesome-icon icon="plus" class="fa-2x " />
              </div>
              <div class="total">
                <h1 class="mb-0 mr-3">
                    {{
                      this.$route.params.eleccion === 'D' ?
                      estadisticas.votos_casilla_especial_mr_numero :
                      estadisticas.votos_casilla_especial_numero
                    }}
                </h1>
              </div>
            </div>
            <!-- <b-progress max="100" :value="this.$route.params.eleccion === 'D' ?
              estadisticas.votos_casilla_especial_mr_porcentaje :
              estadisticas.votos_casilla_especial_porcentaje" /> -->
          </div>

            <div class="votosTotalesAvance" v-if="eleccion === 'D' && noEstaFiltrado">
              <div class="grilla opacidad mb-2 d-flex align-items-center" >
                <div class="leyenda  sombra d-flex">
                  <div class="iconos mt-2 ml-2">
                    <img src="@/assets/circle-c.png" alt="">
                  </div>
                  <p class="font-weight-bold mb-0 ">                  
                    Votos en Casillas <br> Especiales RP
                  </p>
                  
                </div>
                <div class="icono-suma col-2">
                      <font-awesome-icon icon="plus" class="fa-2x " />
                </div>
                <div class="total">
                  <h1 class="mb-0 mr-3">
                    {{ estadisticas.votos_casilla_especial_rp_numero }}
                  </h1>
                </div>
              </div>
              <!-- <b-progress max="100" :value="estadisticas.votos_casilla_especial_rp_porcentaje" /> -->
            </div>
        </div>
        

          <div class=" total-votos votosTotalesAvance d-flex justify-content-around ">
            <div class="grilla leyenda2 opacidad mt-1 mb-2 col-12 d-flex align-items-center">
              <div class="col-4 pt-3">
                <h5>
                  <strong>Total de Votos </strong>                 
                </h5>
              </div>
              <div class="icono-suma ">
                    <font-awesome-icon icon="equals" class="fa-2x " />
              </div>
              <div class="total-de-votos " :class="{'primario' : this.$store.state.esOscuro}">                
                <h2>
                  {{ estadisticas.total_votos_general_numero }}
                  <strong>
                    <!-- {{ estadisticas.total_votos_general_numero }} -->
                    <div class="pl-3" v-if="noEstaFiltrado">
                      <!-- {{ sumaTotal }} -->
                    </div>
                    <div class="pl-3" v-else>
                      <!-- {{ sumaTotalF }} -->
                    </div>
                  </strong>                                   
                </h2>
              </div>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.iconos{
  img{
    height: 18px;
    width: 18px;
  }
}
.contorno {
  border: 3px dashed #707070;
  border-spacing: 100px;

 
}
.votosTotalesAvance {
  p,h4 {
    margin: 10px;
  }
  p{
    font-size: 16px;
  }
  h1{
    font-size: 48px;
  }
  .leyenda1{
    background-color: rgba(78, 78, 78, 0.1);
  }
  .leyenda2{
    background-color: rgba(196, 196, 196, 0.5);
  }
  .opacidad{
    opacity: .5;
  }
}
@media (max-width: 768px) {
 .votosTotalesAvance{
  p,h5{
    font-size: 0.8rem;
  }
  h1{  
      font-size: 32px;
  
  }
  // h5{
  //   font-size: 15px;
  // }
  .icono-suma{
    align-items: right;
    max-width: 20px;
    margin-right: 10px;
  }
  .total{
      h1{
        margin-left: 15px;
        font-size: 1.5rem;
        strong{
          margin-left: 5px;
          font-size: 1.8rem;
        }
      }  
  }
  .total-de-votos{
    h2{
      strong{
        margin-left: 10px;
        margin-right: 5px;
        font-size: 1.8rem;
      }
    }
  }
 }

}

</style>


<script>
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import Urbano from '@/components/Movil/componentes/Iconos/Urbano';
import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
import NoUrbano from '@/components/Movil/componentes/Iconos/NoUrbano';

export default {
  name: 'EstadisticaEntidad',
  components: {
    ProgresoVertical,
    GraficaListaNominal,
    Urbano,
    NoUrbano,
  },
  data() {
    return {
      randomKey: Math.random(),
      noEstaFiltrado : true,
      isMobile: true,

    };
  },
  created() {

  },
  mounted() {
    // this.noEstaFiltrado = this.$route.params.municipio
    // Agrega un listener para detectar cambios en la media query
    const mediaQuery = window.matchMedia("( max-width: 768px)");
    this.handleMediaQuery(mediaQuery);
    // mediaQuery.addListener(this.handleMediaQuery);

  },
  beforeDestroy() {
    this.noEstaFiltrado = this.$route.params.distrito === undefined
   
  },
  computed: {
    sumaTotal() {  
      const votosCasilla = this.estadisticas.votos_casilla_basica_contigua_extraordinaria_numero;
      const votosCasillaMR = this.estadisticas.votos_casilla_especial_mr_numero  ?? this.estadisticas.votos_casilla_especial_numero;
      const votosCasillaRP = this.estadisticas.votos_casilla_especial_rp_numero;

      let sumaTotal = 0;
      //Quita la coma
      if (votosCasilla !== '0') {
        sumaTotal += parseInt(votosCasilla.replace(',', ''));
      }
      if (votosCasillaMR !== '0') {
        sumaTotal += parseInt(votosCasillaMR.replace(',', ''));
      }
      if (votosCasillaRP !== '0' ) {
        sumaTotal += parseInt(votosCasillaRP.replace(',', ''));
      }
      //Regresa la suma y le agrega la coma
      return sumaTotal.toLocaleString('en-US');
    },
    sumaTotalF() {  
      const votosCasilla = this.estadisticas.votos_casilla_basica_contigua_extraordinaria_numero;
      const votosCasillaMR = this.estadisticas.votos_casilla_especial_mr_numero  ?? this.estadisticas.votos_casilla_especial_numero;

      let sumaTotal = 0;
      //Quita la coma
      if (votosCasilla !== '0') {
        sumaTotal += parseInt(votosCasilla.replace(',', ''));
      }
      if (votosCasillaMR !== '0') {
        sumaTotal += parseInt(votosCasillaMR.replace(',', ''));
      }      
      //Regresa la suma y le agrega la coma
      return sumaTotal.toLocaleString('en-US');
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelEntidad;
      }
      return this.$store.state.gEstadisticasNivelEntidad;
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'C791A8';
      }
      return 'C791A8';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'B283B9';
      }
      return 'B283B9';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return '582D73';
      }
      return '582D73';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'F1B1D7';
      }
      return 'C791A8';
    },
    colorNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'E97FBD';
      }
      return '79144c';
    },
    styleLetter() {
      if (this.$store.state.esOscuro) {
        return 'color:black';
      }
      return 'color:white';
    },
    styleUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #C791A8 !important; color: black !important;';
      }
      return 'background-color: #C791A8 !important;';
    },
    styleNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #E97FBD !important; color: black !important;';
      }
      return 'background-color: #582D73 !important;'; 
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
  },
  
  methods: {
    handleMediaQuery(mediaQuery) {
      this.isMobile = mediaQuery.matches;
    },
    obtenerAvance(avance) {
      // console.log(avance);
      return Math.ceil(parseFloat(avance));
    },
    
  },
  watch: {
    eleccion: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.randomKey = Math.random();
        }
      },
    },
    
  },
};
</script >

