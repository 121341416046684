<template>
  <section class="acceso-representacion-proporcional">
    <p class="text-center font-weight-bold">
      <big>
        Representación Proporcional
      </big>
    </p>

    <p>
      El apartado "Actas RP" muestra la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional, los cuales se suman únicamente a nivel Entidad.
    </p>

    <router-link to="/representacion-proporcional" class="btn btn-primary d-block mx-auto text-center mb-3">
      Ver Actas
    </router-link>
  </section>
</template>

<script>
  export default {
    name: 'AccesoRepresentacionProporcional'
  }
</script>

<style lang="scss" scoped>
.acceso-representacion-proporcional {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 45px;
  padding: 30px;
  width: 600px;

  p{
    color: #582D73;

  }
  
  .btn {
    background-color:#C791A8;
    color: #000000;
    font-weight: bold;
    border-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    max-width: 600px;
    display: block;
    margin: 0 auto;

    .btn {
      max-width: 150px;
    }
  }
}

@media (max-width: 768px) {
  .acceso-representacion-proporcional{
    background-color:#F5F5F5 ;
    width: 110%;
    p{
      font-size: 15px;
      text-align: justify;
      big{
        font-size: 20px;
        font-weight: bolder;
      }    
    } 
    .btn {
      width: 200px;
      border-radius: 10px;

  }
  }

}
</style>